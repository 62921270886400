<script setup lang="ts">
interface ILogo {
  imageClass?: string;
  containerStyle?: string;
}

const props = withDefaults(defineProps<ILogo>(), {
  imageClass: '',
  containerStyle: '',
});

const branding = useBranding();
</script>

<template>
  <img
    fetchpriority="high"
    :key="branding.logo.value"
    :class="props.imageClass"
    :src="branding.logo.value"
    alt="Jackpotcity"
  />
</template>

<style scoped></style>
