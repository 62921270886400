import { useLocaleStore } from '~/stores/locale.store';
import { useSiteStore } from '~/stores/site.store';

export const useFormatCurrency = (
  value: number | bigint,
  truncated?: boolean,
  customClass?: string,
): string => {
  const localeStore = useLocaleStore();
  const siteStore = useSiteStore();
  const notationValue = value > 999 && truncated ? 'compact' : 'standard';
  const copyClass = customClass ? customClass : 'gold-gradient-text';
  const formatter = new Intl.NumberFormat(localeStore?.getLocale, {
    style: 'currency',
    currency: siteStore.getCurrencyCode,
    currencyDisplay: 'narrowSymbol',
    notation: notationValue,
  });
  return formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          return `<strong class="${copyClass}">${value}</strong>`;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
};

export const useFormatNumberCompact = (value: number): string => {
  const localeStore = useLocaleStore();
  const siteStore = useSiteStore();
  const formatter = new Intl.NumberFormat(localeStore?.getLocale, {
    notation: 'compact',
  });
  return formatter.format(value);
};

export const useFormatToMonthAsString = (value: Date | number): string => {
  const localeStore = useLocaleStore();
  const formatter = new Intl.DateTimeFormat(localeStore?.getLocale, {
    month: 'long',
  });
  return formatter.format(value);
};

export const useFormatOnlySymbol = (): string => {
  const localeStore = useLocaleStore();
  const siteStore = useSiteStore();
  interface NumberFormatPart {
    type: string;
    value: string;
  }
  const formatter = new Intl.NumberFormat(localeStore?.getLocale, {
    style: 'currency',
    currency: siteStore.getCurrencyCode,
    currencyDisplay: 'narrowSymbol',
  });
  const parts: NumberFormatPart[] = formatter.formatToParts(0);
  const findCurrency: NumberFormatPart = parts.find(
    (part: NumberFormatPart): boolean => part.type === 'currency',
  );
  return findCurrency.value;
};

export const useFormatTruncateNumber = (value: number): string => {
  const localeStore = useLocaleStore();
  const notationValue = value > 999 ? 'compact' : 'standard';
  const formatter = new Intl.NumberFormat(localeStore?.getLocale, {
    style: 'decimal',
    notation: notationValue,
    roundingMode: 'halfTrunc',
  });
  return formatter.format(value);
};
