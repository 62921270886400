import { useQueryHooks } from '~/utilities/query-hooks';
import { unlockBodyScroll } from '~/utilities/ui-utilities';
import { useSiteStore } from '~/stores/site.store';
import { globalKeys } from 'assets/data/global-keys';

export default defineNuxtPlugin(() => {
  addRouteMiddleware(
    'generic-middleware',
    (to) => {
      const { $storageService } = useServices();
      const siteStore = useSiteStore();
      const errorExclusions = [
        '/playground',
        '/promotions',
        '/health',
        '/big-city-life',
        '/blog',
        '/favorites',
        '/winners',
        '/winners-circle',
        '/suggest-and-win',
      ];
      const redirects = [...siteStore.getRedirects];
      const isRedirectAvailable = redirects.find((k) => {
        return k?.fromLink === to.path;
      });

      if (!!isRedirectAvailable) {
        return navigateTo(isRedirectAvailable?.toLink, { redirectCode: 301 });
      } else {
        const page = computed(() => {
          if (to.params.contentType === 'home' || to.path === '/')
            return siteStore.getSitemapMap.get(`/`);
          return siteStore.getSitemapMap.get(
            `/${String(to.params.contentType)}`,
          );
        });

        if (
          typeof page.value === 'undefined' &&
          !errorExclusions.some((e) => to.fullPath.startsWith(e)) &&
          siteStore.getSitemapMap.size !== 0
        ) {
          showError({
            statusCode: 404,
            statusMessage: 'Page Not Found',
            fatal: false,
            unhandled: false,
          });
        } else {
          const isVertical =
            page.value?.MobileApp?.component === 'HomeComponent' ||
            page.value?.MobileApp?.component === 'CasinoComponent';
          $storageService.setCookie({
            key: globalKeys.navigationKeys.lastRoute,
            data: to.path,
            expiry: 7,
          });
          if (!!to.params.contentType && isVertical)
            $storageService.setCookie({
              key: globalKeys.navigationKeys.lastVertical,
              data: to.params.contentType,
              expiry: 7,
            });
          if (!!to.params.category)
            $storageService.setCookie({
              key: globalKeys.navigationKeys.lastCategory,
              data: to.params.category,
              expiry: 7,
            });
        }
      }

      nextTick(() => {
        unlockBodyScroll();
        window?.scrollTo({
          top: 0,
        });
      });
    },
    { global: true },
  );
  addRouteMiddleware(
    'query-hook',
    async (to) => {
      const { query } = to;
      const queryKeys = Object.keys(query);
      if (!!query && queryKeys?.length) {
        useQueryHooks(to);
      }
    },
    { global: true },
  );
});
