import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import type { IFavouriteResponse } from '~/interfaces/dto/games/response';

export default async function ({ game }): Promise<IFavouriteResponse> {
  const { $gameService } = useServices();
  const auth = useAuthStore();
  const page = useGetPage();

  return await $gameService.addGameFavourites({
    channel: 'WebDesktop',
    languageCode: 'en-US',
    vertical: page.value?.Mapping,
    currency: 'USD',
    token: auth.access_token,
    game: game,
  });
}
