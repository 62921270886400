import { useSiteStore } from '~/stores/site.store';
import type { ISitemapRemap } from '~/interfaces/dto/sitemap/response';

export default function useGetPage(
  path: string = '',
  setSeoMeta: boolean = true,
) {
  const route = useRoute();
  const settings = useSiteStore();

  const getPath = (path: string = ''): Partial<ISitemapRemap> => {
    if (path === 'undefined') path = '';
    return (
      settings.getSitemapMap.get(`/${path}`) ||
      settings.getSitemapMap.get(`/${path}/`)
    );
  };
  const page = ref<Partial<ISitemapRemap>>({});

  watchEffect(() => {
    const pageValue =
      route.params.contentType !== 'home'
        ? getPath(path || String(route.params.contentType))
        : getPath();
    page.value = pageValue ?? getPath();

    if (setSeoMeta)
      useSeoMeta({
        ...page.value?.nuxtSeoParams,
      });
  });

  return page;
}
