import {
  defaultLocales,
  environmentVariables,
  subdomainLocales,
} from '~/assets/data/config';
import { globalKeys } from 'assets/data/global-keys';

interface IEnvironment {
  host?: string;
  environment?: {
    testHost?: boolean;
    localhost?: boolean;
    localeSubdomainHost?: null | string[];
    isLatestBuild: boolean;
  };
  storageConfigs?: {
    debugRegionCode?: string;
    debugEnvironment?: string;
    localeCookie?: string;
  };
  regionConigs?: {
    countryCode?: string;
    presetLocale?: string;
    runtimeEnvironment: string;
    isGlobal: boolean;
  };
}

export default function useEnvironment(): IEnvironment {
  const { $storageService } = useServices();
  const runtimeConfig = useRuntimeConfig();
  const siteStore = useSiteStore();
  const route = useRoute();
  const colorMode = useColorMode();

  const host = useRequestURL().host.split(':')[0];

  // url tests
  const testHost = computed(() => !!host.match(/uat|dev|qa/));
  const localhost = computed(() => !!host.match(/localhost/));
  const localeSubdomainHost = computed(() => host.match(/en|pt|sw/));

  // storage
  const debugRegionCode = $storageService.getUniversalStorage<string>({
    key: globalKeys.developerConfigKeys.debugRegion,
  });
  const debugEnvironment = $storageService.getUniversalStorage<string>({
    key: globalKeys.developerConfigKeys.debugEnvironment,
  });
  const localeCookie = $storageService.getCookie({
    key: globalKeys.siteConfigKeys.locale,
  });

  const currentBuildTimeStamp = $storageService.getUniversalStorage<string>({
    key: globalKeys.siteConfigKeys.buildTimestamp,
  });

  const theme = $storageService.getLocalStorage<string>({
    key: globalKeys.siteConfigKeys.colorMode,
  });

  // configurations
  const latestBuildTimestamp = runtimeConfig?.public.buildTimestamp;
  const isLatestBuild = +currentBuildTimeStamp !== +latestBuildTimestamp;

  if (!isLatestBuild) $storageService.invalidateFullCache();
  $storageService.setUniversalStorage({
    key: globalKeys.siteConfigKeys.buildTimestamp,
    data: runtimeConfig?.public.buildTimestamp,
  });

  if (!!debugEnvironment) {
    siteStore.setEnvironment(debugEnvironment);
    Object.assign(
      runtimeConfig?.public,
      environmentVariables[debugEnvironment],
    );
  } else if (localhost.value) {
    siteStore.setEnvironment(runtimeConfig.public.environment);
    Object.assign(
      runtimeConfig?.public,
      environmentVariables[runtimeConfig.public.environment],
    );
  }
  if (!theme) {
    $storageService.setLocalStorage({
      key: globalKeys.siteConfigKeys.colorMode,
      data: colorMode.preference,
    });
  }

  const countryCode = localhost.value
    ? debugRegionCode || runtimeConfig?.public.defaultCountry
    : host?.slice(-2);

  const presetLocale =
    testHost.value && !!route.query?.locale
      ? String(route.query?.locale)
      : !!localeSubdomainHost.value
        ? subdomainLocales[localeSubdomainHost.value[0]]
        : !!localeCookie && (testHost.value || localhost.value)
          ? localeCookie
          : defaultLocales[`J${countryCode.toUpperCase()}`];

  $storageService.setUniversalStorage({
    key: globalKeys.siteConfigKeys.environment,
    data: runtimeConfig?.public.environment,
  });

  return {
    host,
    environment: {
      testHost: testHost.value,
      localhost: localhost.value,
      localeSubdomainHost: localeSubdomainHost.value,
      isLatestBuild,
    },
    storageConfigs: { debugRegionCode, debugEnvironment, localeCookie },
    regionConigs: {
      countryCode,
      presetLocale,
      runtimeEnvironment: runtimeConfig?.public.environment,
      isGlobal:
        document.location.hostname.includes('jackpotcitycasino') ||
        String(runtimeConfig.public.defaultGlobalBranding) === 'true',
    },
  };
}
