<script setup lang="ts">
import LazyImage from '../lazy-image.vue';

interface IExternalIconProps {
  iconName: string;
  themed?: boolean;
}

const props = withDefaults(defineProps<IExternalIconProps>(), {
  themed: true,
});
const imagePath = useImagePath;
const { getPlaceholder, getDynamicImage } = useBranding();
</script>
<template>
  <LazyImage
    :class="['mx-auto', { 'invert dark:invert-0': props.themed }]"
    :placeholder="getPlaceholder('iconPlaceholder')"
    :src="getDynamicImage('casinoCategoryIconV2', { icon: props.iconName })"
  />
</template>
