<script setup lang="ts">
import AppDownload from '~/components/user-interface/app-download.vue';
import { globalKeys } from 'assets/data/global-keys';

const { getImage } = useBranding();

const { $storageService } = useServices();
const { $enabled } = useNuxtApp();
const devicesList: string[] = ['iPhone', 'Android', 'Huawei'];

interface IAppDownloadProps {
  type?: string;
  background?: string; //background of the link divs
  direction?: 'vertical' | 'horizontal';
}

withDefaults(defineProps<IAppDownloadProps>(), {
  direction: 'horizontal',
});

const deviceName = computed(() => {
  const currentDeviceName: string = devicesList.find(
    (device) => navigator.userAgent.toString().indexOf(device) > -1,
  );

  return !!currentDeviceName ? currentDeviceName : 'Desktop';
});

const displayApple = computed(
  () =>
    (deviceName.value === 'iPhone' || deviceName.value === 'Desktop') &&
    ($enabled('website.enableappdownload') ||
      $enabled('website.enableappleappdownload')),
);
const displayAndroid = computed(
  () =>
    (deviceName.value === 'Desktop' ||
      deviceName.value === 'Android' ||
      deviceName.value === 'Huawei') &&
    ($enabled('website.enableappdownload') ||
      $enabled('website.enableandroidappdownload')),
);

const displayHuawei = computed(
  () =>
    (deviceName.value === 'Desktop' ||
      deviceName.value === 'Android' ||
      deviceName.value === 'Huawei') &&
    ($enabled('website.enableappdownload') ||
      $enabled('website.enablehuaweiappdownload')),
);

const displayGooglePlay = computed(
  () =>
    (deviceName.value === 'Desktop' ||
      deviceName.value === 'Android' ||
      deviceName.value === 'Huawei') &&
    ($enabled('website.enableappdownload') ||
      $enabled('website.enablegoogleplayappdownload')),
);

function downloadApp() {
  const btag = $storageService.getCookie({
    key: globalKeys.marketingKeys.bannerTag,
  });
  window.open(
    `https://info.jpc.africa/api/v1/JackpotCityAppDownload/?btag=${
      !!btag ? btag : 'P97425-PR26622-CM89032-TS1984022'
    }`,
  );
}
</script>

<template>
  <div
    class="flex flex-center flex-wrap gap-2"
    :class="direction === 'vertical' ? 'flex-col w-full' : 'flex-row'"
    @click="downloadApp()"
  >
    <AppDownload
      v-if="displayApple"
      :class="{ 'w-full': direction === 'vertical' }"
    >
      <template #icon>
        <img
          element-name="appleIcon"
          class="dark:invert"
          :src="getImage('apple')"
          alt="Jackpotcity Apple App"
        />
      </template>
      <template #appType>
        {{ $t('app-store') }}
      </template>
    </AppDownload>
    <AppDownload
      v-if="displayAndroid"
      :class="{ 'w-full': direction === 'vertical' }"
    >
      <template #icon>
        <img
          element-name="androidIcon"
          :src="getImage('android')"
          alt="Jackpotcity Android App"
        />
      </template>
      <template #appType>
        {{ $t('android') }}
      </template>
    </AppDownload>
    <AppDownload
      v-if="displayHuawei"
      :class="{ 'w-full': direction === 'vertical' }"
    >
      <template #icon>
        <img
          element-name="huaweiIcon"
          :src="getImage('huawei')"
          alt="Jackpotcity Huawei App"
        />
      </template>
      <template #appType>
        {{ $t('app-gallery') }}
      </template>
    </AppDownload>
    <div
      v-if="displayGooglePlay"
      :class="{ 'w-full': direction === 'vertical' }"
    >
      <img
        :class="{
          'h-12': direction === 'vertical',
          'h-10': direction === 'horizontal',
          'cursor-pointer': true,
        }"
        element-name="google-play-icon"
        :src="getImage('googlePlay')"
        alt="Jackpotcity Google Play App"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
