import {
  dynamicImages,
  logos,
  placeholders as placeholderSets,
  standardImages,
  type TDynamicImageKeys,
  type TPlaceholderKeys,
  type TStandardImageKeys,
} from '~/assets/data/branding-library';

export default function useBranding() {
  const colorMode = useColorMode();
  const environment = useEnvironment();
  const imagePath = useImagePath;
  const lookup = computed(() =>
    environment.regionConigs.isGlobal ? 'global' : 'za',
  );

  const logo = ref<string>();
  const logoSets = logos[lookup.value];
  watchEffect(() => {
    logo.value = imagePath(logoSets[colorMode.preference]);
  });

  const placeholders = placeholderSets[lookup.value];

  const getPlaceholder = (key: TPlaceholderKeys) =>
    imagePath(placeholders[key]);
  const getImage = (key: TStandardImageKeys) => imagePath(standardImages[key]);
  const getDynamicImage = (
    key: TDynamicImageKeys,
    replaceValue: Record<string, string>,
  ) => {
    let path = String(dynamicImages[key]);
    const replaceValues = Object.entries(replaceValue);

    replaceValues.forEach(([k, v]) => {
      path = path.replace(`{${k}}`, v);
    });
    return imagePath(path);
  };

  return {
    logo,
    logoSets,
    placeholders,
    getPlaceholder,
    getImage,
    getDynamicImage,
  };
}
