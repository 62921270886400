<script setup lang="ts">
import { SunIcon, MoonIcon } from '@heroicons/vue/24/solid';
import Button from '../../components/design-system/button.vue';
import { globalKeys } from 'assets/data/global-keys';
const colorMode = useColorMode();
const { $storageService } = useServices();

const toggleDark = () => {
  if (colorMode.preference === 'dark') {
    colorMode.preference = 'light';
  } else {
    colorMode.preference = 'dark';
  }
  $storageService.setLocalStorage({
    key: globalKeys.siteConfigKeys.colorMode,
    data: colorMode.preference,
  });
};
</script>
<template>
  <Button type="tertiary" @click="toggleDark()">
    <component :is="colorMode.preference === 'dark' ? SunIcon : MoonIcon" />
  </Button>
</template>
