
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91game_93_46clienti7c4z1nMJsMeta } from "/home/vsts/work/1/s/pages/[contentType]/[category]/[game].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/vsts/work/1/s/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as indexu2Behuxh8YMeta } from "/home/vsts/work/1/s/pages/[contentType]/[category]/index.vue?macro=true";
import { default as _91category_93TFv9kQpq4mMeta } from "/home/vsts/work/1/s/pages/[contentType]/[category].vue?macro=true";
import { default as indexdCM0b7EuXoMeta } from "/home/vsts/work/1/s/pages/[contentType]/favorites/index.vue?macro=true";
import { default as indexVljXzLykFtMeta } from "/home/vsts/work/1/s/pages/[contentType]/index.vue?macro=true";
import { default as _91provider_93TnjMx1FOtiMeta } from "/home/vsts/work/1/s/pages/[contentType]/providers/[provider].vue?macro=true";
import { default as indexzxGJcsPKWaMeta } from "/home/vsts/work/1/s/pages/[contentType]/providers/index.vue?macro=true";
import { default as indexumaoIa8piHMeta } from "/home/vsts/work/1/s/pages/[contentType]/recently-played/index.vue?macro=true";
import { default as _91contentType_93759yoqNeMyMeta } from "/home/vsts/work/1/s/pages/[contentType].vue?macro=true";
import { default as _404UvIQCsXWa8Meta } from "/home/vsts/work/1/s/pages/404.vue?macro=true";
import { default as _91post_93jZbkmI2pWzMeta } from "/home/vsts/work/1/s/pages/big-city-life/[category]/[subcategory]/[post].vue?macro=true";
import { default as indexOJRJMayKEmMeta } from "/home/vsts/work/1/s/pages/big-city-life/[category]/[subcategory]/index.vue?macro=true";
import { default as indexlPXg6c0o2RMeta } from "/home/vsts/work/1/s/pages/big-city-life/[category]/index.vue?macro=true";
import { default as indexEf5VuBArNpMeta } from "/home/vsts/work/1/s/pages/big-city-life/index.vue?macro=true";
import { default as _91post_93LaI5XTf7uZMeta } from "/home/vsts/work/1/s/pages/blog/[category]/[post].vue?macro=true";
import { default as indexP6mMKqiGldMeta } from "/home/vsts/work/1/s/pages/blog/[category]/index.vue?macro=true";
import { default as indexIlMVldWmXUMeta } from "/home/vsts/work/1/s/pages/blog/index.vue?macro=true";
import { default as indexGnNrF0cSdbMeta } from "/home/vsts/work/1/s/pages/get-the-app-2/index.vue?macro=true";
import { default as healthgO3FPzNA7uMeta } from "/home/vsts/work/1/s/pages/health.vue?macro=true";
import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as _91promotion_937Cx8935bsJMeta } from "/home/vsts/work/1/s/pages/promotions/[promotion].vue?macro=true";
import { default as index1zF81j02A3Meta } from "/home/vsts/work/1/s/pages/promotions/index.vue?macro=true";
import { default as indexMZKqO9bCdoMeta } from "/home/vsts/work/1/s/pages/suggest-and-win/index.vue?macro=true";
import { default as indexAV418VWz51Meta } from "/home/vsts/work/1/s/pages/winners-circle/index.vue?macro=true";
import { default as indexK6X6IxObplMeta } from "/home/vsts/work/1/s/pages/winners/index.vue?macro=true";
export default [
  {
    name: _91contentType_93759yoqNeMyMeta?.name,
    path: "/:contentType()",
    component: () => import("/home/vsts/work/1/s/pages/[contentType].vue"),
    children: [
  {
    name: _91category_93TFv9kQpq4mMeta?.name,
    path: ":category()",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/[category].vue"),
    children: [
  {
    name: "contentType-category-game",
    path: ":game()",
    component: () => createClientPage(() => import("/home/vsts/work/1/s/pages/[contentType]/[category]/[game].client.vue"))
  },
  {
    name: "contentType-category",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/[category]/index.vue")
  }
]
  },
  {
    name: "contentType-favorites",
    path: "favorites",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/favorites/index.vue")
  },
  {
    name: "contentType",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/index.vue")
  },
  {
    name: "contentType-providers-provider",
    path: "providers/:provider()",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/providers/[provider].vue")
  },
  {
    name: "contentType-providers",
    path: "providers",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/providers/index.vue")
  },
  {
    name: "contentType-recently-played",
    path: "recently-played",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/recently-played/index.vue")
  }
]
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/home/vsts/work/1/s/pages/404.vue")
  },
  {
    name: "big-city-life-category-subcategory-post",
    path: "/big-city-life/:category()/:subcategory()/:post()",
    component: () => import("/home/vsts/work/1/s/pages/big-city-life/[category]/[subcategory]/[post].vue")
  },
  {
    name: "big-city-life-category-subcategory",
    path: "/big-city-life/:category()/:subcategory()",
    component: () => import("/home/vsts/work/1/s/pages/big-city-life/[category]/[subcategory]/index.vue")
  },
  {
    name: "big-city-life-category",
    path: "/big-city-life/:category()",
    component: () => import("/home/vsts/work/1/s/pages/big-city-life/[category]/index.vue")
  },
  {
    name: "big-city-life",
    path: "/big-city-life",
    component: () => import("/home/vsts/work/1/s/pages/big-city-life/index.vue")
  },
  {
    name: "blog-category-post",
    path: "/blog/:category()/:post()",
    component: () => import("/home/vsts/work/1/s/pages/blog/[category]/[post].vue")
  },
  {
    name: "blog-category",
    path: "/blog/:category()",
    component: () => import("/home/vsts/work/1/s/pages/blog/[category]/index.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/vsts/work/1/s/pages/blog/index.vue")
  },
  {
    name: "get-the-app-2",
    path: "/get-the-app-2",
    component: () => import("/home/vsts/work/1/s/pages/get-the-app-2/index.vue")
  },
  {
    name: "health",
    path: "/health",
    meta: healthgO3FPzNA7uMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/health.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/pages/index.vue")
  },
  {
    name: "promotions-promotion",
    path: "/promotions/:promotion()",
    component: () => import("/home/vsts/work/1/s/pages/promotions/[promotion].vue")
  },
  {
    name: "promotions",
    path: "/promotions",
    component: () => import("/home/vsts/work/1/s/pages/promotions/index.vue")
  },
  {
    name: "suggest-and-win",
    path: "/suggest-and-win",
    component: () => import("/home/vsts/work/1/s/pages/suggest-and-win/index.vue")
  },
  {
    name: "winners-circle",
    path: "/winners-circle",
    component: () => import("/home/vsts/work/1/s/pages/winners-circle/index.vue")
  },
  {
    name: "winners",
    path: "/winners",
    component: () => import("/home/vsts/work/1/s/pages/winners/index.vue")
  }
]