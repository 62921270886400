import { defineCustomElement } from 'vue';
import { useContentService } from '~/composables/services/useContentService';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import Button from '~/components/design-system/button.vue';
import FourOFour from '~/components/user-interface/404.vue';
import css from 'assets/css-modules/kentico-shadow.css?inline';

export const KenticoShadow = defineCustomElement(
  (props) => {
    const { $t } = useNuxtApp();

    const localeStore = useLocaleStore();
    const contentService = useContentService();
    const colorMode = useColorMode();

    const parsedContentInner = ref<string>('');
    const renderFallback = ref<boolean>(false);
    const loading = ref<boolean>(true);
    const accordion = ref<boolean>(false);
    const expandedContent = ref<boolean>(false);
    const activeAccordion = ref<Element>();
    const router = useRouter();
    const route = useRoute();

    const shadowRoot = useShadowRoot();

    const openAccordion = (accordion) => {
      activeAccordion.value = accordion;
      const content = accordion.querySelector('.panel-collapse');
      accordion.classList.add('accordion-active');
      content.style.opacity = 1;
      content.style.height = 'auto';
      content.style.padding = '12px 15px 12px 14px';
      accordion.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    };
    const closeAccordion = (accordion) => {
      const content = accordion.querySelector('.panel-collapse');
      accordion.classList.remove('accordion-active');
      content.style.opacity = 0;
      content.style.height = 0;
      content.style.padding = 0;
    };
    function closeOpenedAccordion(clickedAccordion) {
      //check if active accordion exists and if its the same as the accordion being clicked
      if (
        !!activeAccordion.value &&
        activeAccordion.value !== clickedAccordion
      ) {
        closeAccordion(activeAccordion.value);
      }
    }
    function toggleAccordion(accordion: Element) {
      if (accordion.classList.contains('accordion-active')) {
        closeAccordion(accordion);
      } else {
        openAccordion(accordion);
      }
    }

    function checkDefaultAccordion() {
      //ID for accordion that must open on Mounted
      const accordionId = route?.query?.accordion;
      if (!!accordionId) {
        const btag = !!route.query?.btag ? { btag: route.query?.btag } : {};
        router.replace({ query: { ...btag } });
        const accordionContent = document?.getElementById(String(accordionId));
        //Defensive programming against bad query
        if (!!accordionContent) {
          const accordion = accordionContent?.parentElement;
          if (!!accordion) {
            //open specified accordion on mounted
            openAccordion(accordion);
          }
        }
      }
    }

    function assignClickHandlers() {
      const accordions = shadowRoot.querySelectorAll('.panel');
      accordions.forEach((accordion) => {
        try {
          const accordionHeader = accordion.querySelector('.panel-heading');
          const accordionToggle = accordion.querySelector('.accordion-toggle');
          const accordionContent: HTMLElement =
            accordion.querySelector('.panel-collapse');
          accordionContent.tabIndex = -1;
          accordionContent.style.opacity = '0';
          accordionContent.style.height = '0';
          accordionContent.style.padding = '0';
          accordionContent.style.overflow = 'hidden';
          accordionToggle.removeAttribute('href');
          accordionHeader.removeAttribute('href');
          if (
            accordionHeader?.querySelector('a')?.innerHTML === '\n\n\n\n' ||
            accordionHeader?.querySelector('a')?.innerHTML?.trim() === ''
          ) {
            accordionHeader?.parentElement?.remove();
          }
          accordionHeader.addEventListener('click', () => {
            closeOpenedAccordion(accordion); //close any other accordions that are open
            toggleAccordion(accordion);
          });
        } catch (e) {
          console.error(e);
        }
      });
    }

    function fetchContent() {
      contentService
        .fetchContent(props.content)
        .then((data) => {
          if (!!data?.content) {
            parsedContentInner.value = data.content;
            loading.value = false;
            // if (data.content.startsWith('<error')) emits('noContent', true);
          } else {
            loading.value = false;
            // emits('noContent', true);
          }
        })
        .finally(() => {
          assignClickHandlers();
          checkDefaultAccordion();
        })
        .catch((e) => {
          console.error({ e });
          // emits('noContent', true);
          loading.value = false;
        });
    }

    onMounted(() => {
      fetchContent();
    });

    watch(
      () => localeStore.getLocale,
      (value, oldValue) => {
        if (value !== oldValue) fetchContent();
      },
    );

    return () => (
      <div class={{ [colorMode.preference]: true }}>
        <link rel="stylesheet" href="/_nuxt/jackpotcity-styles.css" />
        <div
          class={{ 'text-base-priority container': true }}
          key={props.content}
        >
          {!parsedContentInner.value &&
            !renderFallback.value &&
            loading.value && <GenericLoader />}
          <div>
            <div
              id="content-box"
              class={{
                hidden: renderFallback.value,
                'content--page kentico-content text-base-priority': true,
                accordion: accordion.value,
                'seo mt-2': props.seoContent,
                'seo-wrapper': props.seoContent && !expandedContent.value,
              }}
              ref="kentico"
              innerHTML={parsedContentInner.value}
            ></div>
            {props.seoContent && parsedContentInner.value && (
              <div class="w-full flex justify-center">
                <Button
                  type="transparent"
                  onClick={() =>
                    (expandedContent.value = !expandedContent.value)
                  }
                >
                  {expandedContent.value ? $t('show-less') : $t('show-all')}
                </Button>
              </div>
            )}
            {renderFallback.value && props.terminateToError && <FourOFour />}
            <div id="scripts-holder" />
          </div>
        </div>
      </div>
    );
  },
  {
    props: {
      content: { type: String, default: '/' },
      seoContent: { type: Boolean, optional: true, default: false },
      terminateToError: { type: Boolean, optional: true, default: false },
    },
    styles: [css],
    shadowRoot: true,
  },
);
