import { useAuthStore } from '~/stores/auth.store';
import { useGameStore } from '~/stores/games.store';
import { useSiteStore } from '~/stores/site.store';
import { useRgStore } from '~/stores/responsibleGaming.store';
import { useServices } from '~/composables/useServices';
import type { IGameCategory } from '~/interfaces/dto/games/response';
export default async function ({
  game,
  freePlay = false,
  fromProvider = false,
  fromFavorites = false,
  fromGoldenCircle = false,
}: {
  game: IGameCategory;
  freePlay?: boolean;
  fromProvider?: boolean;
  fromFavorites?: boolean;
  fromGoldenCircle?: boolean;
}): Promise<void> {
  const { $gameService } = useServices();
  const { $enabled } = useNuxtApp();
  const router = useRouter();
  const route = useRoute();
  const auth = useAuthStore();
  const games = useGameStore();
  const settings = useSiteStore();
  const rgStore = useRgStore();
  const { name, id, categories } = game;
  const cats: string[] = Object.keys(categories || {});
  // game = await $gameService.fetchGame({
  //   gameId: +id,
  //   regionCode: settings.getRegionCode,
  //   vertical: String(route.params.contentType),
  //   channel: 'webdesktop',
  //   count: 1,
  //   languageCode: 'en-US',
  //   currency: 'USD',
  // });

  if (settings.isWebview) {
    useLaunchGamePostMessage(id);
    return;
  }
  if (auth.loggedIn && !!auth.currentUser) {
    if (!auth.getIsUserCompliant && $enabled('myaccount.enablecompliance')) {
      auth.setAccountModalPage('document-verification');
      return;
    }
    if (!rgStore?.allowWager) {
      settings.activateModal('accountRestricted');
      return;
    }
  }
  if (
    !auth.loggedIn &&
    !freePlay &&
    !game.freeplay &&
    !$enabled('website.enablefreeplay')
  ) {
    // settings.activateModal('login');
    games.setCurrentGame(game);
    router.push({
      query: {
        redirect: `/${route.params.contentType || 'home'}/${
          route.params.category || cats[0].toLowerCase()
        }/${game.alias}`,
      },
    });
    return;
  }

  if (settings.modalActive) {
    settings.toggleFeature({ feature: 'displaySearch' });
    settings.deactivateModal();
  }

  try {
    const freePlayQs = freePlay ? { freePlay: 'true' } : {};
    const providerQs = fromProvider ? { provider: 'true' } : {};
    const favoriteQs = fromFavorites ? { favorite: 'true' } : {};
    const gcQs = fromGoldenCircle ? { gc: 'true' } : {};
    router.push({
      name: 'contentType-category-game',
      params: {
        contentType: route.params.contentType || 'home',
        category: route.params.category || cats[0].toLowerCase(),
        game: game.alias,
      },
      query: { ...freePlayQs, ...providerQs, ...favoriteQs, ...gcQs },
    });
    window?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  } catch (e) {
    console.error(e);
    settings.toggleSiteNotice({
      heading: 'Error',
      message: `Could not launch ${name}, please try again later.`,
      severity: 'error',
      enabled: true,
      timeout: 15000,
    });
  }
}
