export type TPlaceholderKeys =
  | 'winnersMobile'
  | 'winnersDesktop'
  | 'winnersPlaceholderMobile'
  | 'winnersPlaceholderDesktop'
  | 'marketingPlaceholderMobile'
  | 'marketingPlaceholderDesktop'
  | 'sideBannerPlaceholder'
  | 'promotionsPlaceholder'
  | 'bannerPlaceholderMobile'
  | 'bannerPlaceholderDesktop'
  | 'contentPostPlaceholder'
  | 'contentCardPlaceholder'
  | 'masonryPlaceholderOne'
  | 'masonryPlaceholderTwo'
  | 'categoryCardPlaceholder'
  | 'iconPlaceholder';

export type TStandardImageKeys =
  | 'positionGold'
  | 'positionSilver'
  | 'positionBronze'
  | 'hotGames'
  | 'mostPopular'
  | 'winnersIcon'
  | 'mostLiked'
  | 'notFoundLight'
  | 'notFoundDark'
  | 'huawei'
  | 'android'
  | 'apple'
  | 'googlePlay'
  | 'exclusive'
  | 'mpumalangaEconomicRegulator'
  | 'freeplayOverlay'
  | 'merLicense';

export type TDynamicImageKeys =
  | 'casinoCategoryIconV2'
  | 'casinoProviders'
  | 'bankingIcon';

interface ILogoBrandingImages {
  za: Record<'light' | 'dark', string>;
  global: Record<'light' | 'dark', string>;
}

interface IPlaceholderBrandingImages {
  za: Record<TPlaceholderKeys, string>;
  global: Record<TPlaceholderKeys, string>;
}

export const logos: ILogoBrandingImages = {
  za: {
    light: '/medialibraries/content.jpc.africa/branding/jpccasino_sa_black.svg',
    dark: '/medialibraries/content.jpc.africa/branding/jpccasino_sa_white.svg',
  },
  global: {
    light:
      '/medialibraries/content.jpc.africa/branding/jpccasino_global_black.svg',
    dark: '/medialibraries/content.jpc.africa/branding/jpccasino_global_white.svg',
  },
};

export const placeholders: IPlaceholderBrandingImages = {
  za: {
    winnersMobile: `/medialibraries/Jackpotcity.co.za/content-pages/Winners/BigWinnersPage_MBHomeBanner_720_500.png`,
    winnersDesktop: `/medialibraries/Jackpotcity.co.za/content-pages/Winners/BigWinnersPage_DTLobbyBanner_1600_300.png`,
    winnersPlaceholderMobile: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_720_500.png`,
    winnersPlaceholderDesktop: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_1600_300.png`,
    marketingPlaceholderMobile: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_720_500.png`,
    marketingPlaceholderDesktop: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_1600x500.png`,
    sideBannerPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_254_524.jpg`,
    promotionsPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_388_100.jpg`,
    bannerPlaceholderMobile: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_720_200.png`,
    bannerPlaceholderDesktop: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_1600_300.png`,
    contentPostPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_1600x500.png`,
    contentCardPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_500x500.jpg`,
    masonryPlaceholderOne: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_720_500.png`,
    masonryPlaceholderTwo: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_254x524.jpg`,
    categoryCardPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_sa_placeholder_500x500.jpg`,
    iconPlaceholder:
      '/medialibraries/content.gmgamingsystems.com/Casino-Category-Icons/v2/all.svg',
  },
  global: {
    winnersMobile: `/medialibraries/Jackpotcity.co.za/content-pages/Winners/BigWinnersPage_MBHomeBanner_720_500.png`,
    winnersDesktop: `/medialibraries/Jackpotcity.co.za/content-pages/Winners/BigWinnersPage_DTLobbyBanner_1600_300.png`,
    winnersPlaceholderMobile: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_720_500.png`,
    winnersPlaceholderDesktop: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_1600_300.png`,
    marketingPlaceholderMobile: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_720_500.png`,
    marketingPlaceholderDesktop: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_1600x500.png`,
    sideBannerPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_254_524.jpg`,
    promotionsPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_388_100.jpg`,
    bannerPlaceholderMobile: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_720_200.png`,
    bannerPlaceholderDesktop: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_1600_300.png`,
    contentPostPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_1600x500.png`,
    contentCardPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_500x500.jpg`,
    masonryPlaceholderOne: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_720_500.png`,
    masonryPlaceholderTwo: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_254x524.jpg`,
    categoryCardPlaceholder: `/medialibraries/content.jpc.africa/branding/jpccasino_global_placeholder_500x500.jpg`,
    iconPlaceholder:
      '/medialibraries/content.gmgamingsystems.com/Casino-Category-Icons/v2/all.svg',
  },
};

export const standardImages: Record<TStandardImageKeys, string> = {
  positionGold:
    '/medialibraries/Jackpotcity.co.za/content-pages/Winners/icon-type-gold.svg',
  positionSilver:
    '/medialibraries/Jackpotcity.co.za/content-pages/Winners/icon-type-silver.svg',
  positionBronze:
    '/medialibraries/Jackpotcity.co.za/content-pages/Winners/icon-type-bronze.svg',
  hotGames:
    '/medialibraries/Jackpotcity.co.za/content-pages/Winners/icon-hot-games.svg',
  mostPopular:
    '/medialibraries/Jackpotcity.co.za/content-pages/Winners/icon-most-popular.svg',
  winnersIcon:
    '/medialibraries/Jackpotcity.co.za/content-pages/Winners/icon-winner.svg',
  mostLiked:
    '/medialibraries/Jackpotcity.co.za/content-pages/Winners/icon-most-liked.svg',
  notFoundLight:
    '/medialibraries/Jackpotcity.co.za/content-pages/404_light.png',
  notFoundDark: '/medialibraries/Jackpotcity.co.za/content-pages/404_dark.png',
  huawei: '/medialibraries/content.jpc.africa/icons/huawei.svg',
  android: '/medialibraries/content.jpc.africa/icons/android.svg',
  apple: '/medialibraries/content.jpc.africa/icons/apple.svg',
  googlePlay: '/medialibraries/content.jpc.africa/icons/google_play_badge.png',
  exclusive:
    '/medialibraries/content.gmgamingsystems.com/Casino-Games/Game Backgrounds/JPCExclusive.svg',
  mpumalangaEconomicRegulator:
    '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/mpumalanga-economic-regulator.png',
  freeplayOverlay:
    '/medialibraries/content.gmgamingsystems.com/Casino-Games/Game%20Backgrounds/jpc_freeplay_overlay.png',
  merLicense:
    '/medialibraries/Jackpotcity.co.za/document/Jackpot_City_Licence_Certificate_with_Sub_URL.pdf',
};

export const dynamicImages: Record<TDynamicImageKeys, string> = {
  casinoCategoryIconV2:
    '/medialibraries/content.gmgamingsystems.com/Casino-Category-Icons/v2/{icon}.svg',
  casinoProviders:
    '/medialibraries/content.gmgamingsystems.com/casino-providers/{provider}.png',
  bankingIcon:
    '/medialibraries/content.gmgamingsystems.com/jackpotcity/banking/{provider}_{theme}.png',
};
