<script setup lang="ts">
interface IThemedImage {
  imageClass?: string;
  altText?: string;
  light: string;
  dark: string;
}

const props = withDefaults(defineProps<IThemedImage>(), {
  altText: '',
  imageClass: '',
});

const image = ref<string>(props.dark);

const { getPlaceholder } = useBranding();
watch(
  () => useColorMode(),
  (value) => {
    nextTick(() => {
      image.value = value.preference === 'dark' ? props.dark : props.light;
    });
  },
  { immediate: true, deep: true },
);
</script>

<template>
  <DesignSystemLazyImage
    :key="image"
    :class="props.imageClass"
    :src="image"
    :placeholder="getPlaceholder('iconPlaceholder')"
    :alt="altText"
  />
</template>

<style scoped></style>
